import styled, { css } from 'styled-components';
export * from '../updates/common.css';

export const Section = styled.section`
  position: relative;
  color: white;
  ${({ backgroundImageUrl }) =>
    backgroundImageUrl
      ? css`
        background-size: cover;
        background-image: url(${backgroundImageUrl});
      `
      : css`
        background-color: var(--header-bg);
      `
  }
  --alternative-heading-color: white;
  --primary-color: white;
  --secondary-color: #343434;
  padding: var(--py) 0 0;
  --py: 36px;
  @media (min-width: 24em) {
    --py: 48px;
  }
  @media (min-width: 36em) {
    --py: 60px;
  }
  @media (min-width: 48em) {
    --py: 72px;
  }
`

export const sectionCover = css`
  position: relative;
  color: white;
  --alternative-heading-color: white;
  --primary-color: white;
  --secondary-color: #343434;
  padding: var(--py) 0;
  --py: 36px;
  @media (min-width: 24em) {
    --py: 48px;
  }
  @media (min-width: 36em) {
    --py: 60px;
  }
  @media (min-width: 48em) {
    --py: 100px;
  }
`;

export const layoutGrid = css`
  display: grid;
  align-items: center;
  gap: 48px;
  grid-template-columns: repeat(var(--cols, 1), minmax(0, 1fr));
  @media (min-width: 60em) {
    --cols: 2;
    /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
  }
`;

export const h1 = css`
  margin: 0;
  font-weight: 900;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 4px);
  --fz: 36px;
  @media (min-width: 24em) {
    --fz: 42px;
  }
  @media (min-width: 36em) {
    --fz: 48px;
  }
  @media (min-width: 48em) {
    --fz: 56px;
  }
`;

export const preHeading = css`
  margin: 0;
`

export const subheading = css`
  margin: 0;
  margin-top: 24px;
  font-size: 16px;
  @media (min-width: 40em) {
    font-size: 18px;
  }
`;

export const buttonFlow = css`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, max-content);
  @media (min-width: 36em) {
    grid-template-columns: repeat(2, max-content);
  }
  gap: 24px;
`;

export const btn = css`
  min-width: min-content;
  margin: 0;
`;

export const textStack = css`
  margin: var(--py) 0;

  --py: 36px;
  @media (min-width: 24em) {
    --py: 48px;
  }
  @media (min-width: 36em) {
    --py: 60px;
  }
  @media (min-width: 48em) {
    --py: 72px;
  }
  /* display: flex;
  align-items: center; */
`;

export const imageHold = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > * {
    flex: 0 0 auto;
    width: 100%;
  }
  &::before {
    content: '';
    display: block;
    width: 0.05px;
    height: 0;
    padding-bottom: calc(100% / var(--aspect));
  }
  --aspect: 4;
  width: 85%;
  margin-left: auto;
  @media (min-width: 36em) {
    width: 75%;
    --aspect: 1.6;
  }
  @media (min-width: 60em) {
    width: 100%;
    --aspect: 1.6;
  }
`;
