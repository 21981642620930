import React from 'react';
import { PageHeader } from 'src/layouts/PageHeader';
import { Button } from 'src/components/atoms/Button';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as sys from '../../system';
import * as css from './header-home.css';

export default function HomeHeader({
  heading,
  preHeading,
  contentNode,
  image,
  ctas,
  blocks,
  customData,
  backgroundImage
}) {
  customData = JSON.parse(customData);
  return (
    <PageHeader
      {...{
        alternative: false,
        primary: true,
        dark: false,
        ...customData?.flags
      }}
    >
      <css.Section backgroundImageUrl={backgroundImage?.url}>
        <div css={[sys.frame, sys.wrapLast, css.layoutGrid]}>
          <div css={[css.textStack]}>
            {preHeading && <p className="f-mono" css={[css.preHeading]}>{preHeading}</p>}
            <h1 css={[css.h1]}>
              {heading && heading.split('\\n').map((str, i) => (
                <React.Fragment key={i}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </h1>
            <div
              css={[css.subheading]}
              dangerouslySetInnerHTML={{
                __html: contentNode.childMarkdownRemark.html,
              }}
            />
            <ul role="list" css={[css.buttonFlow]}>
              {ctas.map((cta, i) => (
                <li role="listitem" key={cta.text}>
                  <Button
                    css={[css.btn]}
                    to={cta.url}
                    aria-label={cta.a11yText || cta.text}
                    secondary={i > 0}
                  >
                    {cta.text}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          {!!image && (
            <div css={[css.imageHold]}>
              <FluidImg data={image} maxWidth={800} priority fadeIn={0} />
            </div>
          )}
        </div>
      </css.Section>
    </PageHeader>
  );
}
